<form [formGroup]="passForm" (ngSubmit)="changePass()" accept-charset="utf-8" class="change-pass-container" [ngClass]="{'popover': isPopover}">
  <div class="form-group-container">
    <label class="form-label" for="password">{{"CHANGE_PASS.labelPass" | translate}}</label>
    <div class="pass-container">
      <input type="password" id="newpassword" name="password" formControlName="password" class="form-input"
        placeholder='{{"CHANGE_PASS.labelPass" | translate}}'>
      <ion-button fill="clear" class="show-pass-button" (click)="showHidePass()">
        <ion-icon class="icon" *ngIf="!showPass" name="eye-outline"></ion-icon>
        <ion-icon class="icon" *ngIf="showPass" name="eye-off-outline"></ion-icon>
      </ion-button>
    </div>
  </div>
  <div class="form-group-container">
    <label class="form-label" for="repPassword">{{"CHANGE_PASS.labelRepPass" | translate}}</label>
    <div class="pass-container">
      <input type="password" id="repPassword" name="repPassword" formControlName="repPassword" class="form-input"
        placeholder='{{"CHANGE_PASS.labelRepPass" | translate}}'>
      <ion-button fill="clear" class="show-pass-button" (click)="showHideRepPass()">
        <ion-icon class="icon" *ngIf="!showRepPass" name="eye-outline"></ion-icon>
        <ion-icon class="icon" *ngIf="showRepPass" name="eye-off-outline"></ion-icon>
      </ion-button>
    </div>
  </div>
  <div *ngIf="!isPopover" class="form-group-container button">
    <button type="submit" class="submit-button" [ngClass]="{ 'disabled': !isFormValid() }">
      {{"CHANGE_PASS.button" | translate}}
    </button>
  </div>
  <div *ngIf="isPopover" class="buttons-container">
    <button type="button" class="cancel" (click)="goBack()">
      {{"CHANGE_PASS.back" | translate}}
    </button>
    <button type="submit" class="submit" [ngClass]="{ 'disabled': !isFormValid() }">
      {{"CHANGE_PASS.button" | translate}}
    </button>
  </div>
</form>